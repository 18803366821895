jQuery(document).ready(function ($) {
"use strict"; 
/*$(".first_class_name").on("click", function (e) {
$(this).addClass("current_cat_post").siblings().removeClass("current_cat_post");
});
  var jl_stick;
    if ($(".jl_menu_sticky").hasClass('jl_stick')) {
        var jl_stick = $(".jl_menu_sticky").offset().top;
    }
    $(".jl_menu_sticky.jl_stick").addClass("jl_sticky");
    var nav_height = $(".jl_menu_sticky.jl_stick").outerHeight();
    $(".jl_blank_nav").css({
        "height": nav_height
    }); 
$('.menu_mobile_icons, .mobile_menu_overlay').on("click", function() {
        $('#content_nav').toggleClass('jl_mobile_nav_open');
        $('.mobile_menu_overlay').toggleClass('mobile_menu_active');
        $('.mobile_nav_class').toggleClass('active_mobile_nav_class');
});

$("#mobile_menu_slide .menu-item-has-children > a").append($("<span/>",{class:'arrow_down'}).html('<i class="far fa-angle-down" aria-hidden="true"></i>'));
$("#mainmenu .menu-item-has-children > a").append('<i class="far fa-angle-down" aria-hidden="true"></i>'); 
$('#mobile_menu_slide .menu-item-has-children > a').on("click",  function() { 
            var $submenu = $(this).closest('.menu-item-has-children').find(' > .sub-menu');
            $(this).find('.arrow_down i').toggleClass("far fa-angle-down").toggleClass("far fa-angle-up");
			$(this).toggleClass("link-active");
            if ( $submenu.hasClass('menu-active-class') ) {
                $submenu.removeClass('menu-active-class');
            } else {
                $submenu.addClass('menu-active-class');
            }
            return false;
        });

$('.search_form_menu_personal_click').on("click", function() {
      $(".search_btn").val('');
      $('.search_form_menu_personal').toggleClass('search_form_menu_personal_active');
      $('.mobile_nav_class').toggleClass('active_mobile_nav_class');
      
});

$('.single_post_share_icons').on("click", function() {
      $('.single_post_share_wrapper').toggleClass('share_single_active');
      $('.mobile_nav_class').toggleClass('active_mobile_nav_class');
});


$('.search_form_menu_click').on('click', function ( e ) {
    e.preventDefault();
      $('.search_form_menu').toggle();
    $(this) .toggleClass('active');
    });


 if ( $('.sb-toggle-left').length ) {
            $('.sb-toggle-left').on("click",  function(){
                $('#nav-wrapper').toggle(100);
            } ); 
            $("#menu-main-menu .menu-item-has-children > a").append($("<span/>",{class:'arrow_down'}).html('<i class="far fa-angle-down"></i>'));     
        }
        
        $('#nav-wrapper .menu .arrow_down').on("click",  function() {
            var $submenu = $(this).closest('.menu-item-has-children').find(' > .sub-menu');
            
            if ( $submenu.hasClass('menu-active-class') ) {
                $submenu.removeClass('menu-active-class');
            } else {
                $submenu.addClass('menu-active-class');
            }
            
            return false;
        });
    $('#menu_wrapper li').hover(function(){
    var marginAdjust = 100;
    var parentElement = $(this).parent();
    
    var navPosition = $(parentElement).position();
    var navWidth = $(parentElement).width();
    var navRight = navPosition.left+navWidth;
    
    var position = $(this).position();
    var thisWidth = $(this).children('ul').width();
    var thisRight = position.left+thisWidth-marginAdjust;
    
    if (thisRight > navWidth) $(this).children('ul').addClass('jl_menu_tls');
    });*/
  jQuery(window).scroll(function () {
    /*var scroll = $(window).scrollTop();
    if (scroll >= 100) {
        $(".jl_large_menu_logo").addClass("jl_custom_height_small");
        $(".options_dark_header").addClass("dark_header_menu");
    } else {
        $(".jl_large_menu_logo").removeClass("jl_custom_height_small");
        $(".options_dark_header").removeClass("dark_header_menu");
    }*/
    if (jQuery(this).scrollTop() > 500) {
      jQuery("#go-top").fadeIn();
    } else {
      jQuery("#go-top").fadeOut();
    }
  });  
  $("#go-top").on("click", function () {
	  var ua = window.navigator.userAgent.match(/(chrome)\/?\s*(\.?\d+(\.\d+)*)/i)//opera|chrome|safari|firefox|msie
	  if(ua)
	  {
		  window.scrollTo({  top: 0,  behavior: 'smooth',});
	  }
	  else
	  {
      jQuery("body,html").animate({ scrollTop: 0 }, 800 );
	  }
       return false;
  }); 
var tagrtl = $("html").attr("dir");
if(tagrtl){var rtl_options = true;}else{var rtl_options = false;}
if(is_home.is_home==1) {
$('.home_slider_header_tab').slick({
    rtl: rtl_options,
    dots: false,
    speed: 600,
    autoplaySpeed: 6000,
    autoplay: true,
    pauseOnHover: false,
    adaptiveHeight: true,
    fade: true,
    prevArrow:'',
    nextArrow:'',
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.home_slider_header_tab_nav',
    touchMove: false    
  });  
$('.home_slider_header_tab_nav').slick({
  rtl: rtl_options,
  slidesToShow: 4,
  slidesToScroll: 4,
  asNavFor: '.home_slider_header_tab',
  focusOnSelect: true,
  responsive: [
    {
      breakpoint: 1196,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4
      }
    }
  ]
});
}
});

$(document).on('click', '.accordian-checkbox', function() {      
    $('.accordian-checkbox').not(this).prop('checked', false);      
});  


